/* fichier JS de base commun quelques soient les versions du projet web */

// import 'bootstrap'
import 'bootstrap/js/dist/collapse'
import 'bootstrap/js/dist/dropdown'

// lazy loading => https://github.com/aFarkas/lazysizes
// import lazySizes from 'lazysizes'
import 'lazysizes/plugins/aspectratio/ls.aspectratio'
/* global Waypoint */
import 'waypoints/lib/jquery.waypoints.min.js'

// bootstrap => customisez bootstrap directement depuis un fichier custom et sélectionnez les modules communs utilisés sur toutes les pages du site au lieu de tout importer
import './scss/bootstrap'
// global styles
import './scss/custom'

// Scripts librairies
import { handle, sticky } from './js/libs/misc'
import './js/libs/utils'
// import './js/components/cookie.js'

// lazySizes.cfg.expand = 80
// lazySizes.cfg.expFactor = 1
$('body').removeClass('no-js')

// lazysizes : add simple support for background images :
document.addEventListener('lazybeforeunveil', (e) => {
  const bg = e.target.getAttribute('data-background')
  if (bg) e.target.style.backgroundImage = 'url(' + bg + ')'
})

// Scroll to top
async function getScrollToTop () {
  import(/* webpackChunkName: "scrollToTop" */ './js/components/scrollToTop.js')
}

// Quick Search Modal
async function getQsModal () {
  // $f.load()
  import(/* webpackChunkName: "quickSearchModal" */ './js/components/quickSearchModal.js').then(() => {
    // $f.load(false)
  })
}

// My selection
async function getMyselection ($f, open = false, $add = null) {
  $f.load()
  import(/* webpackChunkName: "mySelection" */ './js/components/mySelection.js').then(module => {
    module.mySelection.init(open)
    if ($add !== null) { $add.trigger('click') }
    $f.load(false)
  })
}

// Swiper Carousel
async function getSwiperCarousel () {
  import(/* webpackChunkName: "swiperCarousel" */ './js/components/swiperCarousel.js').then(() => {
    Waypoint.refreshAll()
  })
}

// Actions principales communes à toutes les pages
$(document).ready(function () {
  // sur le scroll
  const w = $(window)
  w.scroll(function () {
    if (w.scrollTop() >= 200 && !$('#scrollUp').length) { getScrollToTop() }
    sticky()
  })

  // bouton recherche rapide
  $('.qsModal').on('click', function () {
    $(this).off()
    getQsModal()
  })

  // bouton ma sélection
  $('.cart-button').on('click', function () {
    $(this).off()
    getMyselection($('.cart-button'), true)
  })

  // boutons wish add
  $('.add-wish').on('click', function (e) {
    e.stopPropagation()
    getMyselection($('.cart-button'), false, $(this))
  })

  // gestion des carousel
  const $s = $('.swiper-carousel')
  if ($s.length) {
    $s.waypoint(function () {
      getSwiperCarousel()
      this.destroy()
    }, {
      offset: '100%'
    })
  }

  // gestion blogpost items
  const $blogitem = $('.blogpost-item')
  if ($blogitem.length) {
    $blogitem.waypoint(function () {
      import(/* webpackChunkName: "blogPostItem" */ './scss/components/blogPostItem.scss').then(() => {
        Waypoint.refreshAll()
      })
      this.destroy()
    }, {
      offset: '100%'
    })
  }

  // gestion advice items
  const $adviceitem = $('.advice-item')
  if ($adviceitem.length) {
    $adviceitem.waypoint(function () {
      import(/* webpackChunkName: "advicePostItem" */ './scss/components/advicePostItem.scss').then(() => {
        Waypoint.refreshAll()
      })
      this.destroy()
    }, {
      offset: '100%'
    })
  }

  // gestion cta
  const $cta = $('.cta')
  if ($cta.length) {
    $cta.waypoint(function () {
      import(/* webpackChunkName: "cta" */ './scss/components/cta.scss').then(() => {
        Waypoint.refreshAll()
      })
      this.destroy()
    }, {
      offset: '100%'
    })
  }

  // boutons liens
  $('[data-toggle="link"]').on('click', function () {
    if ($(this).attr('data-target') === 'self') { document.location.href = $(this).attr('data-href') } else { window.open($(this).attr('data-href')) }
  })

  // ancre
  $.each($('.scroll'), function (i, elem) {
    $(elem).on('click', function (e) {
      const t = ($(e.target).is('a')) ? this.hash : $(this).attr('target')
      e.preventDefault()
      if ($(t).length > 0) {
        $('html').animate({ scrollTop: ($(t).offset().top - $('#header').height()) - 20 }, { duration: 300, easing: 'linear' }, function () {
          $('html').animate({ scrollTop: ($(t).offset().top - $('#header').height()) - 20 }, { duration: 300, easing: 'linear' })
        })
      }
    })
  })

  // refresh waypoints au chargement d'une image
  $('.lazyload').on('lazyloaded', () => {
    Waypoint.refreshAll()
  })

  // handle sur toutes les pages du site
  handle()
  sticky()
})
