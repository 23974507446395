// Charge la base commune
import './common.js'

// Models Search Form
async function getHmsForm ($f) {
  $f.load()
  import(/* webpackChunkName: "houseModelsSearchForm" */ './js/components/houseModelsSearchForm.js').then(() => {
    /* global Waypoint */
    Waypoint.refreshAll()
    $f.load(false)
  })
}

// Offers Alert Form
async function getOaForm ($f) {
  $f.load()
  import(/* webpackChunkName: "offersAlertForm" */ './js/components/offersAlertForm.js').then(() => {
    Waypoint.refreshAll()
    $f.load(false)
  })
}

// Offers Search Form
async function getOsForm ($f) {
  $f.load()
  import(/* webpackChunkName: "offersSearchForm" */ './js/components/offersSearchForm.js').then(() => {
    Waypoint.refreshAll()
    $f.load(false)
  })
}

// Agencies Search Form
async function getAsForm ($f) {
  $f.load()
  import(/* webpackChunkName: "agenciesSearchForm" */ './js/components/agenciesSearchForm.js').then(() => {
    Waypoint.refreshAll()
    $f.load(false)
  })
}

// Newsletter Form
async function getNForm ($f) {
  $f.load()
  import(/* webpackChunkName: "newsletterForm" */ './js/components/newsletterForm.js').then(() => {
    Waypoint.refreshAll()
    $f.load(false)
  })
}

// Offers Autocomplete
async function getOffersAutocomplete ($f) {
  import(/* webpackChunkName: "offersAutocomplete" */ './js/components/offersAutocomplete.js').then(module => {
    module.offersAutocomplete.init($f)
  })
}

// Agency autocomplete
async function getAgenciesAutocomplete ($f) {
  import(/* webpackChunkName: "agenciesAutocomplete" */ './js/components/agenciesAutocomplete.js').then(module => {
    module.agenciesAutocomplete.init($f)
  })
}

// actions principales communes à toutes les pages
$(document).ready(function () {
  // formulaire de recherche des modèles
  const $c1 = $('form[name="qhmsForm"]')
  if ($c1.length) {
    $c1.waypoint(function () {
      getHmsForm($c1)
      this.destroy()
    }, {
      offset: '100%'
    })
  }

  // formulaire d'inscription aux alertes terrains/offres
  const $c2 = $('form[name="oaForm"]')
  if ($c2.length) {
    $c2.waypoint(function () {
      getOaForm($c2)
      this.destroy()
    }, {
      offset: '100%'
    })
  }

  // gestion de la recherche d'offres
  const $c3 = $('form[name="osForm"]')
  if ($c3.length) {
    $c3.waypoint(function () {
      getOsForm($c3)
      this.destroy()
    }, {
      offset: '100%'
    })
  }

  // gestion de la recherche d'agences la plus proche
  const $c4 = $('form[name="asForm"]')
  if ($c4.length) {
    $c4.waypoint(function () {
      getAsForm($c4)
      this.destroy()
    }, {
      offset: '100%'
    })
  }

  // formulaire newsletter
  const $c5 = $('form[name="nForm"]')
  if ($c5.length) {
    $c5.waypoint(function () {
      getNForm($c5)
      this.destroy()
    }, {
      offset: '100%'
    })
  }

  // filtres des offres autocomplete
  $('form[name="osForm"] input[name="location"]').on('click keyup focus', function () {
    $(this).blur().off().attr('disabled', '').val('Veuillez patienter...')
    getOffersAutocomplete($(this).parents('form'))
  })

  // filtres des agences autocomplete
  $('form[name="asForm"] input[name="location"]').on('click keyup focus', function () {
    $(this).blur().off().attr('disabled', '').val('Veuillez patienter...')
    getAgenciesAutocomplete($(this).parents('form'))
  })
})
